import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { putOwnUserSettings } from '../../lib/Queries';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const currentTime = new Date().toISOString();
  const newUserSettings = {
    last_log_in: currentTime,
  };

  const onRedirectCallback = async (appState) => {
    await putOwnUserSettings(newUserSettings);
    if (appState?.returnTo.includes('login')) {
      return navigate('/');
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    console.error('Auth0 misisng env variable');
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node,
};

export default Auth0ProviderWithNavigate;
