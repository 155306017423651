import { create } from 'zustand';

import {
  clearTimeDataHelper,
  loadCustomerSitesHelper,
  loadKioskDataHelper,
  setErrorHelper,
  setNextConfigComboHelper,
} from './KioskStoreUtil';

type CustomerSite = {
  name: string;
  uuid: string;
};

type SiteMetadata = {
  site_name: string;
  uuid: string;
  timezone: string;
  latitude: number;
  longitude: number;
  address: string;
};

type State = {
  timeSeriesData: any[];
  timeRangeKey: string;
  cycleTimeRangeKeys: string[];
  displayTimeRangeKey: string | null;
  siteMetadata: SiteMetadata | null;
  siteUuid: string | null;
  metricsData: any;
  availableResources: string[] | null;
  customerSites: CustomerSite[];
  isLoading: boolean;
  isLoadingSilent: boolean;
  hasError: boolean;
  mode: string | null;
  login: Function | null;
};

type Actions = {
  setTimeSeriesData: (data: any[]) => void;
  resetTimeRangeKey: () => void;
  setTimeRangeKey: (key: string) => void;
  setDisplayTimeRangeKey: (key: string | null) => void;
  setSiteMetadata: (metadata: any) => void;
  setSiteUuid: (uuid: string | null) => void;
  setMetricsData: (data: any) => void;
  setAvailableResources: (resources: string[]) => void;
  setCustomerSites: (sites: any[]) => void;
  setIsLoading: (loading: boolean) => void;
  setIsLoadingSilent: (loading: boolean) => void;
  loadTimeData: (loadSilently?: boolean) => Promise<void>;
  loadCustomerSites: (customer: any, site: any) => Promise<void>;
  setNextConfigCombo: () => Promise<void>;
  setError: (error: boolean) => void;
  setLogin: (login: Function) => void;
  clearTimeData: () => void;
  clearSiteMetadata: () => void;
  setMode: (mode: string) => void;
  reset: () => void;
};

const cycleTimeRangeKeys = ['lastDay', 'lastWeek'];
const initialTimeRangeKey = cycleTimeRangeKeys[0];

const initialState: State = {
  timeSeriesData: [],
  timeRangeKey: initialTimeRangeKey,
  cycleTimeRangeKeys,
  displayTimeRangeKey: null,
  siteMetadata: null,
  siteUuid: null,
  metricsData: null,
  availableResources: null,
  customerSites: [],
  isLoading: true,
  isLoadingSilent: false,
  hasError: false,
  mode: null,
  login: null,
};

const useStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setTimeSeriesData: (data) => set({ timeSeriesData: data }),
  setTimeRangeKey: (key) => set({ timeRangeKey: key }),
  resetTimeRangeKey: () => set({ timeRangeKey: initialTimeRangeKey }),
  setDisplayTimeRangeKey: (key) => set({ displayTimeRangeKey: key }),
  setSiteMetadata: (metadata) => set({ siteMetadata: metadata }),
  setAvailableResources: (resources) => set({ availableResources: resources }),
  setSiteUuid: (uuid) => set({ siteUuid: uuid }),
  setMetricsData: (data) => set({ metricsData: data }),
  setCustomerSites: (sites) => set({ customerSites: sites }),
  setIsLoading: (loading) => set({ isLoading: loading }),
  setIsLoadingSilent: (loading) => set({ isLoadingSilent: loading }),
  loadTimeData: (loadSilently) => loadKioskDataHelper(get, set, loadSilently),
  loadCustomerSites: (customer, site = null) => loadCustomerSitesHelper(set, get, customer, site),
  setNextConfigCombo: () => setNextConfigComboHelper(get, set),
  setError: (error) => setErrorHelper(set, error),
  clearTimeData: () => clearTimeDataHelper(set),
  clearSiteMetadata: () => set({ siteMetadata: null }),
  setMode: (mode) => set({ mode }),
  setLogin: (login) => set({ login: login }),
  reset: () => {
    set(initialState);
  },
}));

export default useStore;
